body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#map-container {
  height: 100%;
  width: 100%;
  /* temporarily set the background color so we can tell where the map container is positioned */
  background-color: lightgrey;
}

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.reset-button {
  position: absolute;
  top: 50px;
  z-index: 1;
  left: 12px;
  padding: 4px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.mapboxgl-popup-tip,
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  padding: 6px 8px !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;
}
